@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-3xl;
    @apply font-bold;
  }

  h3 {
    @apply text-2xl;
    @apply font-bold;
  }

  h4 {
    @apply text-xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-lg;
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

@layer utilities {
  .overlay-mask {
    mask-image: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 40%);
  }
}

html,
body {
  overscroll-behavior: none;
}

body {
  overflow-y: hidden;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(14, 16, 21, 0.02); /* Transparent background */
  backdrop-filter: blur(10px); /* Background blur */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3); /* Transparent background */
  backdrop-filter: blur(10px); /* Background blur */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Border for glass effect */
  border-radius: 10px;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.5); /* Slightly more opaque on hover */
}
